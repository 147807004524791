import 'modern-normalize';
//import './src/styles/utilities.scss';
import './src/styles/global.scss';
import './src/styles/locale-switcher.scss';
import './src/styles/debug.scss';
import CookieConsenter from './src/util/cookietools';

const consenter = CookieConsenter();
let isUsLocale = false;

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
  geolocate_test();
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const newPath = location.pathname;
  const oldPath = (prevLocation?prevLocation.pathname:null);
  if (newPath!==oldPath) {
    const pagePath = (location ? (location.pathname + location.search + location.hash) : null);
    setTimeout(function() { consenter.trackScripts(isUsLocale, pagePath); }, 32);
  }
};

function geolocate() {
  fetch('/geo').then(res => res.json()).then(results => {
    isUsLocale = (results.country.code==='US');
    // console.log('geolocate: is US locale?', isUsLocale);
  }).catch(error => {
    console.log('onClientEntry unable to query country', error.message);
  });
}

function geolocate_test() {
  if (typeof window === 'undefined') return;
  const urlParams = new URLSearchParams(window.location.search);
  const setAsForeign = urlParams.get('ous');
  if (setAsForeign) {
    console.log('Setting origin as NON-US.');
    isUsLocale = false;
  } else {
    geolocate();
  }
}
